var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card-title',[_c('v-btn',{attrs:{"to":"/financial-statements","color":"#ffffff","light":"","elevation":"0"}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-chevron-left")])],1),_c('h3',[_vm._v("Report: Sales Per Product")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.productsWithSales,"headers":_vm.headers,"group-by":"product_name","sort-by":['product_name', 'units_sold'],"sort-desc":true,"items-per-page":500,"dense":"","search":_vm.search,"loading":_vm.loading,"item-key":"product_name","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Search...","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.returnDisabled},on:{"click":function($event){return _vm.calcSalesPerProduct()}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Populate Report Data")],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{staticClass:"blue-grey lighten-5",attrs:{"colspan":"9"}},[_vm._v(" "+_vm._s(items[0].product_name)+" "),_c('span',{staticClass:"pl-5"},[_vm._v("("+_vm._s(_vm.returnItemTotals(items))+")")]),_c('v-icon',{staticClass:"float-right",on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]}},{key:"item.comments",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"wrap-cell-content"},[_vm._v(_vm._s(item.comments))])]}},{key:"item.order_number",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewSalesOrder(item)}}},[_vm._v(_vm._s(item.order_number))])]}}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }