<template>
  <v-container>
    <!--Header-->
    <v-row dense>
      <v-col>
        <v-card-title>
          <v-btn to="/financial-statements" color="#ffffff" light elevation="0">
            <v-icon left large>mdi-chevron-left</v-icon>
          </v-btn>
          <h3>Report: Sales Per Product</h3>
        </v-card-title>
      </v-col>
    </v-row>
    <!--Data Table-->
    <v-row>
      <v-col>
        <v-data-table
          :items="productsWithSales"
          :headers="headers"
          group-by="product_name"
          :sort-by="['product_name', 'units_sold']"
          :sort-desc="true"
          :items-per-page="500"
          dense
          :search="search"
          :loading="loading"
          item-key="product_name"
          show-select
          v-model="selectedProducts"
        >
          <!--Search-->
          <template v-slot:top
            ><v-text-field
              outlined
              dense
              v-model="search"
              placeholder="Search..."
              append-icon="mdi-magnify"
              clearable
            ></v-text-field
          ></template>
          <!--Empty Table-->
          <template v-slot:no-data>
            <v-card elevation="0" class="pa-5">
              <!--Populate report data-->
              <v-btn
                @click="calcSalesPerProduct()"
                color="primary"
                :loading="loading"
                :disabled="returnDisabled"
              >
                <v-icon small left>mdi-refresh</v-icon>
                Populate Report Data</v-btn
              >
            </v-card>
          </template>
          <!--Group header-->
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="9" class="blue-grey lighten-5">
              {{ items[0].product_name }}
              <span class="pl-5">({{ returnItemTotals(items) }})</span>
              <!--Expand group items-->
              <v-icon @click="toggle" class="float-right"
                >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </th>
          </template>
          <!--Comments-->
          <template v-slot:item.comments="{ item }">
            <td class="wrap-cell-content">{{ item.comments }}</td>
          </template>
          <!--lick to view orders-->
          <template v-slot:item.order_number="{ item }">
            <a @click="viewSalesOrder(item)">{{ item.order_number }}</a>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "../../../components/firebaseInit";
export default {
  name: "SalesPerProductReport",
  data() {
    return {
      productsSet: false,
      salesSet: false,
      search: "",
      loading: false,
      disabled: true,
      products: [],
      sales: [],
      productsLoaded: false,
      salesLoaded: false,
      productsWithSales: [],
      totalsArray: [],
      selectedProducts: [],
      headers: [
        { text: "Product Name", value: "product_name" },
        { text: "Category", value: "product_category" },
        { text: "Sales Order", value: "order_number" },
        { text: "Comment", value: "comments" },
        { text: "Units Sold", value: "units_sold" },
        { text: "Date", value: "sales_date" },
      ],
    };
  },
  computed: {
    returnDisabled() {
      if (this.productsLoaded && this.salesLoaded) return false;
      else return true;
    },
  },
  created() {
    this.getProducts();
    this.getSalesOrders();
  },
  methods: {
    getProducts() {
      const docRef = db.collection("products");
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.products.push(data);
          });
        })
        .then(() => (this.productsLoaded = true))
        .catch((error) => error);
    },
    getSalesOrders() {
      const salesRef = db
        .collection("sales_orders")
        .where("order_status", "!=", "Quote");
      salesRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            this.sales.push(data);
          });
        })
        .then(() => (this.salesLoaded = true))
        .catch((error) => error);
    },
    //
    calcSalesPerProduct() {
      const addSalesDataToProducts = () => {
        this.loading = true;
        this.productsWithSales = [];
        //  Loop through orders
        this.sales.forEach((sale) => {
          //  Loop through products
          this.products.forEach((product) => {
            //  Loop through line items
            sale.line_items.forEach((item) => {
              if (product.product_name !== item.product_name) return;
              const dataObj = {
                product_name: product.product_name,
                product_category: product.product_category,
                units_sold: item.item_qty,
                sales_date: sale.order_date,
                order_number: sale.order_number,
                order_id: sale.order_id,
                comments: item.line_item_comment,
              };
              this.productsWithSales.push(dataObj);
            });
            this.loading = false;
          });
        });
      };
      addSalesDataToProducts();
    },
    // Displays total units sold per item
    returnItemTotals(items) {
      return items.reduce((total, item) => total + item.units_sold, 0);
    },
    // Click to view sales orders in new tab
    viewSalesOrder(item) {
      const routeData = this.$router.resolve({
        path: `/view-order/${item.order_id}`,
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style scoped>
.wrap-cell-content {
  max-width: 300px;
  width: 300px;
}
</style>